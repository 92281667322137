.btn-top-right {
  float: right;
  width: 162px;
}

.user-type .user-type-column:not(:last-child) {
  border-right: solid 1px #202020;
}
.user-type-column {
  width: 50%;
  display: inline-block;
  padding: 12px 14px 0;
  cursor: pointer;
}
.user-type-column.selected {
  color: #0095ff;
  cursor: default;
}
.user-type-column label {
  text-align: center;
  margin: 14px 0;
  cursor: inherit;
}
.user-type-column.selected button {
  color: #0095ff;
  border-color: #0095ff;
  cursor: default !important;
}
.user-type-column-icon {
  width: 100%;
  text-align: center;
}
body .btn {
  line-height: 1.5;
  font-size: inherit;
  transition: all 0.5s;
}

body .btn:hover {
  transition-duration: 0.35s;
}

body .btn:disabled {
  pointer-events: none;
}

body .blue-button {
  font-size: 15px;
  color: white;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(41, 42, 43, 0.2);
  background-color: var(--active-blue-color);
  border: solid 1px rgba(206, 206, 206, 0.3);
}

body .blue-button:hover,
body .blue-button:focus,
body .blue-button:active {
  box-shadow: 0 1px 3px 0 rgba(41, 42, 43, 0.1);
  background-color: var(--active-blue-color-hover);
}

body .blue-white-button {
  background-color: white;
  font-size: 14px;
  color: var(--active-blue-color);
  border: solid 1px var(--active-blue-color);
}

body .black-white-button {
  font-size: 14px;
  color: var(--common-text-color);
  background-color: white;
  border: solid 1px var(--common-text-color);
}

body .red-white-button {
  font-size: 14px;
  color: var(--danger-red-color);
  border: solid 1px var(--danger-red-color);
}

body .danger-button {
  font-size: 14px;
  background-image: linear-gradient(to left, #ab213e, #fe374f);
  color: white;
}

body .disabled-button {
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px var(--common-text-color);
}

body .white-button {
  font-size: 15px;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(41, 42, 43, 0.2);
  background-color: #ffffff;
  border: solid 1px rgba(206, 206, 206, 0.3);
  color: var(--common-text-color);
}

body .white-button:hover,
body .white-button:focus,
body .white-button:active {
  box-shadow: 0 1px 1px 0 rgba(41, 42, 43, 0.1);
  border: solid 1px rgba(206, 206, 206, 0.7);
}

body .white-button-small {
  font-size: 12px;
  border-radius: 3px;
  color: #262626;
  border: solid 1px #edeaea;
  background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0.1), rgba(216, 216, 216, 0.1));
}

body .gray-button {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.36;
  color: #ffffff;
  border-radius: 4px;
  background-color: #273142;
  border: solid 1px #313d4f;
}

body .gray-button:hover,
body .gray-button:focus,
body .gray-button:active {
  background-color: #1a2538;
  border-color: #273142;
}

body button .border-x2 {
  border-width: 2px;
}

body .btn-icon {
  margin-right: 11px;
}

.buttons-bottom {
  display: flex;
  justify-content: flex-end;
  padding: 14px 0;
}

.buttons-bottom button {
  width: 162px;
}

.action-link-text {
  font-size: 12px;
  font-weight: 500;
  color: var(--active-blue-color);
  margin-left: 8px;
  cursor: pointer;
}

body .big-button {
  font-size: 19px;
  font-weight: bold;
}

body .fullwidth-button {
  width: 100%;
}

body .uppercased-button {
  text-transform: uppercase;
  font-weight: normal;
}

.filter-label {
  font-size: 12px;
  border-radius: 3px;
  color: #262626;
  border: solid 1px #edeaea;
  background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0.1), rgba(216, 216, 216, 0.1));
  padding: 9px;
  display: inline-block;
}

.language-selector-container-top-right {
  position: absolute;
  top: 15px;
  right: 7%;
  z-index: 1250;
}

@media screen and (max-width: 767px) {
  .language-selector-container-top-right {
    position: absolute;
    top: 22px;
    right: 15px;
  }
}

.language-selector {
  position: relative;
  width: 79px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
