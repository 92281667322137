@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(/font/Roboto.woff2) format('woff2');
  unicode-range: U + 0000-00ff, U + 0131, U + 0152-0153, U + 02bb-02bc, U + 02c6, U + 02da, U + 02dc,
    U + 2000-206f, U + 2074, U + 20ac, U + 2122, U + 2191, U + 2193, U + 2212, U + 2215, U + FEFF,
    U + FFFD;
}

html,
#root {
  width: 100%;
  height: 100%;
  --active-blue-color: #0095ff;
  --active-blue-color-hover: #0084e2;
  --danger-red-color: #fe374f;
  --common-text-color: #4d4d4d;
  --orange-color: #f79824;
}

.popup-container .popup-overlay {
  background: none !important;
}

.popup-container .popup-content {
  border-radius: 4px;
  box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.18);
  border: none !important;
  text-align: left;
  font-size: 16px;
}

#modal-root .popup-overlay {
  background: rgba(209, 236, 255, 0.18) !important;
  z-index: 3000 !important;
  overflow: auto;
}

#modal-root .popup-content .popup-overlay {
  background: none !important;
}

#modal-root .popup-content:not(.im-tooltip) {
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  border: none !important;
}

#modal-root .popup-content form button[type='submit'] {
  width: 162px;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto;
  font-size: 16px;
  color: var(--common-text-color);
}

.page-header,
.form-header,
.section-header {
  color: black;
}

body .page-header {
  font-size: 28px;
}
body .form-header {
  font-size: 24px;
}
.popup-content .form-header {
  margin-bottom: 24px;
}
body .section-header {
  font-size: 20px;
}

textarea {
  padding: 14px;
}

.section-header-2 {
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 20px;
}

.example-description {
  font-style: italic;
}

.muted-button {
  border-radius: 4px;
  background-color: #f4f4f4;
  border: solid 1px #dfdfdf;
  opacity: 0.5;
  color: #4d4d4d;
}

.blue-color {
  color: var(--active-blue-color);
}

.danger {
  color: var(--danger-red-color);
}

body .icon-container {
  margin-right: 16px;
  display: inline;
}

.section-separator {
  width: 100%;
  height: 1px;
  margin-bottom: 22px;
  opacity: 0.08;
  border-top: solid 1px #000000;
}

.section-separator.with-vertical-margin-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

body .form-group {
  margin-bottom: 22px;
}

body .form-group.with-error {
  margin-bottom: -6px;
}

body .form-group.with-error label {
  color: #fe374f;
}

body .form-group .description {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #959595;
  margin-bottom: 12px;
}

body .form-group.group-header,
body .form-group .group-header {
  font-size: 18px;
}

body label.error {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fe374f;
}

body label.success {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: green;
}

.image-preview-container {
  position: relative;
  display: inline-block;
}

.image-preview-remove {
  position: absolute;
  line-height: 0;
  top: -6px;
  right: -6px;
  cursor: pointer;
}

.sticky-menu .item {
  padding: 8px 17px;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 1px solid transparent;
}

.dashboard-top-menu .item {
  padding: 10px 16px;
  margin-right: 50px;
  cursor: pointer;
  white-space: nowrap;
}

.dashboard-top-menu .active,
.sticky-menu .active {
  color: var(--active-blue-color) !important;
  border-bottom: solid 1px var(--active-blue-color);
}

.im-tooltip {
  display: flex;
  justify-content: center;
  font-size: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08) !important;
  background-color: #f4f4f4 !important;
  border: solid 1px #dfdfdf !important;
}

.letter-circle-container {
  border-radius: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-image: linear-gradient(to bottom, #696969, #000000);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  font-weight: bold;
  margin-right: 10px;
  color: white;
}

.description-uppercase-small {
  font-size: 8px;
  text-transform: uppercase;
}

.error-top {
  font-size: 12px;
  font-weight: 500;
  color: #fe374f;
}

.modal-email-body-textarea {
  width: 100%;
  height: 158px;
}
