.popup-invite-users {
  width: 800px !important;
}

.popup-invite-users .form-group {
  margin-bottom: 0;
}

.popup-invite-users textarea {
  width: 100%;
  height: 100px;
}

.popup-invite-users button {
  margin-top: 42px;
  width: 162px;
}

.popup-edit-user-role {
  width: 1092px !important;
  height: 500px;
}

.popup-edit-user-role button[type='button'] {
  width: 100%;
}

.popup-edit-user-role button[type='submit'] {
  float: right;
  width: 162px;
  margin-top: 47px;
}

.popup-edit-user-role .blue-button.border-x2 {
  font-size: 14px;
}

.popup-user-owner-transfer-confirm-content {
  text-align: center;
}

.popup-user-owner-transfer-confirm-content button {
  font-size: 16px;
  font-weight: bold;
  width: 220px;
}