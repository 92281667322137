.dashboard-container form.quote-request-form textarea {
  height: 170px;
}

.form-group.with-datepicker {
  position: relative;
  z-index: 1400;
}

.quote-form-container {
  position: relative;
  padding-top: 64px;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
}

.quote-form-section-header-container {
  margin-bottom: 80px;
}

.product-photos-row-photo-container {
  width: 130px;
  padding: 0 15px;
  margin-right: 10px;
  margin-bottom: 60px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .quote-form-container {
    width: 100%;
    padding: 60px 15px 30px;
    font-size: 0.95rem;
  }

  .quote-form-section-header-container {
    margin-bottom: 30px;
  }

  .quote-form-section-header-container img {
    margin: 15px 0 25px;
  }

  .product-photos-row-photo-container {
    width: 110px;
    margin-right: 15px;
    margin-bottom: 30px;
  }

  .dashboard-container .sticky-menu {
    top: 0;
    left: 0;
    width: 100%;
    overflow-x: auto;
    z-index: 1111;
    height: auto;
    -webkit-backface-visibility: hidden;
    font-size: 0.9rem;
  }

  .dashboard-container .sticky-menu .item {
    padding: 9px 15px;
  }

  .quote-form-container fieldset .row {
    align-items: flex-end;
  }

  .dashboard-container form.padding-bottom-180 {
    padding-bottom: 110px;
  }

  .landing-quote-form .properties-row.quantities-row {
    padding: 18px 10px;
  }

  .landing-quote-form .quantities-row .form-group {
    padding: 0 14px;
  }

  .landing-quote-form .quantities-row .form-group .form-group {
    padding: 0;
  }

  .landing-quote-form .photo-thumbnail {
    margin: 10px 15px 20px 0;
    width: 70px;
  }

  .landing-quote-form .target-price-value {
    padding: 0;
    margin: -50px 0 -30px;
  }
  .landing-quote-form p {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 500px) {
  .dashboard-container .sticky-menu {
    font-size: 0.8rem;
  }

  .dashboard-container .sticky-menu .item {
    padding: 8px 10px;
  }
}
