.project-container {
  width: 100%;
}

.project-container .add-project-from-container {
  margin-top: 64px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.project-container .add-product-from-container {
  margin-top: 64px;
  width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.project-container .table.actions-first-column thead th:last-child {
  width: 250px;
}

.project-container .table.actions-first-column thead th:first-child {
  width: 70px;
}

.project-settings-users-container form {
  min-height: 300px;
  max-width: 700px;
  padding-bottom: 50px;
  position: relative;
}

.dashboard-container .project-settings-users-container form .actions-bottom {
  border-top: none;
  padding-right: 0;
}

.project-settings-users-container .project-users-table input[type='checkbox'] {
  position: relative;
}

.project-settings-users-container input[type='checkbox'].removed-item:after {
  content: '-';
  color: white;
  width: 14px;
  height: 14px;
  line-height: 14px;
  position: absolute;
  z-index: 600;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: visible;
  background-color: var(--danger-red-color);
}
