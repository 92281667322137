form.landing-quote-form {
  font-size: 18px;
}

.landing-quote-form .form-step-content {
  margin-top: 60px;
}

.landing-quote-big-heading {
  font-size: 32px;
  color: #000;
  margin: 25px 0 45px;
  line-height: normal;
}

.landing-quote-form .form-steps-indicator {
  margin-top: -15px;
}

.landing-quote-form .big-button {
  font-size: 20px;
  height: 56px;
}

.landing-quote-form .highlighted,
.landing-quote-form .form-group .highlighted {
  color: var(--active-blue-color);
}

.landing-quote-image-and-volume-quotes {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 230px;
}

.landing-quote-volume-quotes {
  display: inline-block;
  margin: 0 80px 0 65px;
  vertical-align: middle;
}

.landing-quote-form .volume-quotes-value {
  font-size: 32px;
  color: black;
  margin: 10px 0;
}

.landing-quote-form .volume-quotes-label {
  font-size: 16px;
  color: #bcbcbc;
}

.landing-quote-form .form-step-content .big-input {
  height: 56px;
  font-size: 16px;
}

.landing-quote-form .form-step-1-content p {
  color: black;
}

.landing-quote-form .form-step-1-content .form-group {
  margin-bottom: 30px;
}

.landing-quote-form .form-step-1-content .form-group.with-error {
  margin-bottom: 0;
}

.landing-quote-form .btn.disabled,
.landing-quote-form .btn:disabled {
  opacity: 0.25;
}

.landing-quote-form .error-top {
  margin: 5px 0;
}

.landing-quote-form .blue-label {
  color: var(--active-blue-color);
}

.landing-quote-form .specification-col {
  padding-left: 60px;
}

.landing-quote-form .specification {
  margin-bottom: 35px;
}

.specificaiton-block-bottom {
  padding: 5px 0 0;
  text-align: center;
}

.specification .specification-trigger {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #000000;
  opacity: 0.2;
  transition: all 0.5s;
  transform: rotate(0);
  cursor: pointer;
}

.specification .specification-trigger:hover {
  opacity: 0.4;
}

.specification.opened .specification-trigger {
  transform: rotate(180deg);
}

.landing-quote-form .photo-thumbnail {
  display: inline-block;
  cursor: pointer;
}

.landing-quote-form .photo-thumbnail:last-child {
  margin-right: 0;
}

.landing-quote-form .properties-row {
  position: relative;
  padding: 18px 24px;
  border-bottom: solid 1px #f0f0f0;
}

.landing-quote-form .quantities-row {
  padding-bottom: 0;
  border-bottom: none;
}

.landing-quote-form .quantities-row .form-control {
  height: 45px;
  color: black;
  font-size: 18px;
  padding: 10px 20px;
}

.landing-quote-form .properties-row label {
  color: #868686;
  font-size: 16px;
}

.landing-quote-form .properties-row .error {
  font-size: 12px;
}

.landing-quote-form .properties-row .section-header {
  margin: 0;
  color: black;
  font-size: 22px;
}

.landing-quote-form .property-value {
  color: black;
}

.landing-quote-form .properties-row .quote-currency {
  position: absolute;
  bottom: 20px;
  right: 24px;
  color: #868686;
}

.landing-quote-form .target-price-value {
  padding: 10px 20px;
  color: black;
}

.landing-quote-form .required-quantity {
  color: black;
}

.landing-quote-form .form-step-3-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  height: calc(100vh - 250px);
}

.landing-quote-form-c-container .landing-quote-form .form-step-3-content {
  padding-top: 10px;
  height: auto;
}

.landing-quote-form .form-step-3-content h2 {
  font-size: 32px;
  font-weight: bold;
}

.landing-quote-form .form-step-3-content p {
  max-width: 610px;
  margin: 36px 0 48px;
  font-size: 22px;
}

.landing-quote-form .form-step-3-content .wide-button {
  width: 400px;
}

.landing-quote-form ::-webkit-input-placeholder {
  font-style: italic;
}

.landing-quote-form-b .landing-quote-big-heading {
  margin: 50px 160px 35px;
  line-height: normal;
}

.landing-quote-form-b .landing-quote-big-heading.top-heading {
  margin: initial;
}

.landing-quote-form-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
}

.landing-quote-form-container form .actions-bottom button {
  height: 56px;
}

.landing-quote-form-c-container .quote-request-form .react-datepicker-wrapper input {
  padding-left: 35px;
}

.landing-quote-form-d-container .landing-quote-form .form-steps-indicator {
  margin: 0 auto 120px;
}

.landing-quote-form-d-container .form-step-content label {
  margin-top: 25px;
}

.landing-quote-form-d-container .form-step-content label.error {
  margin-top: 0;
}

.landing-quote-form-d-container .react-datepicker-wrapper {
  width: 100%;
}

.landing-quote-form-d-container .react-datepicker__input-container {
  width: 100%;
}

.dashboard-container .form-group .error {
  color: #fe374f;
}

.landing-quote-form-d-container .heading-row {
  margin-top: 120px;
}

.landing-quote-form-d-container .images-specification {
  margin-top: -18px;
}

@media screen and (max-width: 1230px) {
  .landing-quote-form-container {
    max-width: 100%;
    padding: 21px 15px;
    font-size: 0.8rem;
  }

  .landing-quote-form-container .language-selector-container-top-right {
    top: 21px;
    right: 13px;
  }

  .landing-quote-form .form-steps-indicator {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    width: 90%;
    max-width: 270px;
    margin: 40px auto;
  }

  .landing-quote-form .form-step-1-content {
    margin-top: 140px;
  }

  .landing-quote-form-c-container .landing-quote-form .form-step-1-content {
    margin-top: 80px;
  }

  .landing-quote-image-and-volume-quotes {
    flex-direction: column;
  }

  .landing-quote-image-and-volume-quotes img {
    margin: 30px 0;
  }

  .landing-quote-big-heading {
    margin: 10px 0 35px;
    font-size: 23px;
  }

  .landing-quote-form .form-step-1-content p {
    font-size: 17px;
  }

  .landing-quote-image-and-volume-quotes {
    margin-bottom: 55px;
  }

  .landing-quote-form .specification-col {
    padding: 0;
  }

  .landing-quote-form .volume-quotes-value {
    font-size: 26px;
    text-align: center;
  }

  .landing-quote-form .form-step-2-content {
    margin-top: 115px;
  }

  .landing-quote-form .form-step-3-content {
    height: auto;
    padding-top: 110px;
  }

  .landing-quote-form .form-step-3-content .wide-button {
    width: auto;
    max-width: 290px;
  }

  .landing-quote-form .big-button {
    font-size: 17px;
  }

  .landing-quote-form .form-step-3-content p {
    font-size: 18px;
  }

  .landing-quote-form .form-step-3-content h2 {
    font-size: 27px;
  }

  .landing-quote-form-b .form-step-content {
    margin-top: 55px;
  }

  .landing-quote-form-b .landing-quote-big-heading {
    margin: 30px 0 25px;
    font-size: 23px;
  }

  .landing-quote-form .properties-row label {
    display: inline-block;
    width: calc(100% - 22px);
    vertical-align: top;
  }

  .landing-quote-form-c-container .form-steps-indicator {
    margin: 30px 0;
  }

  .landing-quote-form-d-container .heading-row {
    margin-top: 30px;
  }

  .landing-quote-form-d-container .images-specification .big-product-image {
    width: 290px;
    height: 290px;
  }

  .landing-quote-form-d-container .landing-quote-form .form-steps-indicator {
    font-size: 16px;
    position: relative;
    margin: 0 auto 120px;
  }
}
