.sign-in-form-container .divider {
  width: 100%;
  height: 1px;
  background-color: #323232;
  margin: 20px 0;
}

.sign-in-form-container .gray-button {
  width: 100%;
  height: 36px;
}

.sign-in-form-container, .sign-up-form-container {
  position: relative;
}

.link-forgot-password, .link-sign-in {
  text-align: center;
  opacity: 0.5;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  bottom: -38px;
  width: 100%;
  padding-right: 60px;
  cursor: pointer;
}

.link-forgot-password:hover, .link-forgot-password:focus {
  text-decoration: underline;
}

.link-sign-in {
  bottom: -65px;
  color: #fff;
  line-height: 22px;
}

.link-sign-in:hover {
  color: #fff;
}
