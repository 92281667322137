.dashboard-container .left-menu {
  width: 250px;
  height: 100%;
  object-fit: contain;
  background-color: #080513;
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
}

.dashboard-container .left-menu .sub-menu-items-container.projects {
  max-height: 448px;
  background-color: #007dd7;
  padding: 4px 0;
  position: relative;
}

.dashboard-container .left-menu .sub-menu-items-container.projects.fixed {
  height: 448px;
}

.dashboard-container .dashboard-left-menu .menu-header {
  font-size: 19.8px;
  font-weight: 500;
  letter-spacing: 3.3px;
  color: #ffffff;
  padding: 36px 0 60px 15px;
}

.dashboard-container .dashboard-left-menu .menu-header label {
  margin-left: 10px;
  vertical-align: text-top;
  line-height: 26px;
}

.dashboard-container .dashboard-left-menu .menu-main-section span.text {
  margin-left: 12px;
}

.dashboard-container .dashboard-left-menu .menu-main-section .active {
  color: white;
  pointer-events: none;
}

.dashboard-container .dashboard-left-menu .menu-main-section-header {
  font-size: 10px;
  font-weight: bold;
  padding: 15px 25px 3px;
}

.dashboard-container .dashboard-left-menu .menu-item {
  position: relative;
  height: 50px;
  padding: 14px 25px;
  width: 100%;
  cursor: pointer;
  transition: all 0.35s;
}

.dashboard-container .dashboard-left-menu .menu-item:hover {
  background-color: rgba(28, 28, 28, 0.7);
}
.dashboard-container .dashboard-left-menu .menu-main-section .menu-item:hover {
  color: #a1a1a1;
}

.dashboard-container .dashboard-left-menu .sub-menu .menu-item {
  height: 39px;
  padding: 8px 24px;
  line-height: 1.44;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}

.dashboard-container .dashboard-left-menu .sub-menu.account .menu-item {
  padding: 12px 15px;
  font-size: 14px;
}

.dashboard-container .dashboard-left-menu .menu-item.projects {
  background-color: var(--active-blue-color);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.dashboard-container .dashboard-left-menu .menu-item.account {
  border-radius: 4px;
  margin: 15px;
  width: 220px;
  background-color: #262626;
  color: white;
  display: flex;
  align-items: center;
  padding: 14px 15px;
  cursor: pointer;
  position: absolute !important;
  bottom: 0;
}

.dashboard-container .dashboard-left-menu .menu-item.account:hover {
  background-color: #1c1c1c;
}

.dashboard-container .dashboard-left-menu .sub-menu {
  background-color: var(--active-blue-color);
  color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dashboard-container .dashboard-left-menu .sub-menu.account {
  color: white;
  border-radius: 4px;
  background-color: #262626;
}

.dashboard-container .dashboard-left-menu .sub-menu .menu-item.add-new-project {
  height: 66px;
}

.dashboard-container .dashboard-left-menu .sub-menu .menu-item label {
  margin-left: 17px;
  margin-bottom: 0;
  cursor: pointer;
}

.dashboard-left-menu .menu-item .indicator-new-messages {
  top: calc(50% - 7px);
  margin: 0;
  right: 25px;
  width: 14px;
  height: 14px;
}

.dashboard-left-menu .menu-item .counter-new-messages {
  width: 30px;
  height: 20px;
  font-size: 14px;
}

.dashboard-left-menu .sub-menu-items-container.projects .pagination {
  justify-content: flex-start;
  padding: 6px 18px 13px 18px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pagination-btn {
  margin: 0;
}

.dashboard-left-menu .sub-menu-items-container.projects .menu-item:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.dashboard-left-menu .sub-menu-items-container.projects .pagination-btn-number {
  color: white;
  padding: 6px 4px;
}

.dashboard-left-menu .sub-menu-items-container.projects .pagination-btn-number.active {
  pointer-events: none;
  color: white;
  font-weight: bold;
}

.dashboard-left-menu .menu-projects-search-container {
  position: relative;
}

.dashboard-left-menu .menu-projects-search-container input {
  border: none;
  outline: none;
  width: 245px;
  height: 32px;
  border-radius: 17px;
  background-color: #007dd7;
  margin: 14px 15px;
  padding: 0 32px;
  color: #fff;
}

.dashboard-left-menu .menu-projects-search-container input::placeholder {
  color: #fff;
  opacity: 0.6;
}

.dashboard-left-menu .menu-projects-search-container .search-icon-container {
  position: absolute;
  left: 22px;
  top: 18px;
}

.dashboard-left-menu .menu-projects-search-container .search-clear-icon-container {
  position: absolute;
  left: 26px;
  top: 18px;
  cursor: pointer;
}
