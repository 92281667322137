.upload-button-container {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.hidden-upload-button-file {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}