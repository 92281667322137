.quote-container {
  width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.dashboard-container .product-quote .table tr {
  height: auto;
  padding: 10px 0;
}

.product-quote .table tr td {
  font-size: 14px;
}

.product-quote .table tr td,
.product-quote .table thead th {
  display: inline-block;
  width: 16.66%;
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  padding: 35px 12px;
}

.product-quote .table tr td:first-child,
.product-quote .table thead th:first-child {
  padding-left: 0;
}

.product-quote .table thead th {
  border-bottom: none;
  padding: 22px 12px;
  font-weight: bold;
  color: black;
}

.product-quote .table tr td:last-child {
  text-align: left;
}

.product-quote .quote-creator-details {
  margin-bottom: 30px;
}

.quote-creator-details {
  display: flex;
  align-items: center;
}

.quote-creator-details .name-address {
  margin-right: 60px;
}

.quote-creator-details .name-address .name {
  font-size: 20px;
  font-weight: 500;
}

.quote-creator-details .btn {
  min-width: 174px;
}

.important-mark-note {
  font-size: 10px;
}

.important-mark {
  font-size: 13px;
  color: var(--active-blue-color);
}

.target-mark {
  font-size: 13px;
  color: var(--orange-color);
}

.quote-details {
  padding: 30px 0;
}

.quote-details-value {
  color: black;
  font-weight: 600;
}

.quote-details .row {
  margin: 18px -15px;
}

.product-quote .quote-details .section-header {
  margin: 50px 0 20px;
}

.product-quote .quote-details .section-header-description {
  margin-bottom: 20px;
}

.product-quote .quote-details .section-question {
  margin-top: 30px;
}

.shipped-to {
  font-size: 14px;
  line-height: 1.64;
}

.gray-note {
  opacity: 0.6;
  font-size: 14px;
}
