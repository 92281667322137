.sign-up-complete-container {
  margin-top: 100px;
  width: 720px;
  margin-left: auto;
  margin-right: auto;
}
.sign-up-complete-container .form-header {
  font-size: 24px;
}

.sign-up-complete-container .form-header-icon {
  margin-right: 10px;
}
.sign-up-complete-container .form-header-description {
  margin-bottom: 40px;
}

.sign-up-complete-container .section-header {
  font-size: 20px;
  padding: 65px 0 30px;
}

.sign-up-complete-container .buttons-bottom {
  border-top: solid 1px #d9d9d9;
}

.sign-up-complete-container button[type='submit'] {
  width: 174px;
  float: right;
}

.sign-up-complete-container fieldset {
  padding-bottom: 50px;
  border-bottom: solid 1px #d9d9d9;
}

.sign-up-complete-container fieldset.fieldset-last,
.sign-up-complete-container .fieldset-last {
  padding-bottom: 200px;
  border-bottom: 0;
}

.sign-up-complete-container .sticky-menu {
  background-color: #fff;
  border-bottom: solid 1px #d9d9d9;
  height: 41px;
  padding-top: 8px;
}

.sign-up-complete-container .sticky-menu .item {
  padding: 11px 17px;
}

.sign-up-complete-container .photos-container {
  margin-top: 12px;
}

.sign-up-complete-container .image-preview-container {
  margin-right: 19px;
}

.sign-up-complete-container .img-thumbnail {
  padding: 0;
  border-radius: unset;
  background-color: #f4f3f3;
  border: solid 1px #efefef;
}

.sign-up-complete-container .form-sign-up-complete-invited fieldset.fieldset-last,
.sign-up-complete-container .form-sign-up-complete-invited fieldset {
  padding-bottom: 0;
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .sign-up-complete-container {
    width: calc(100% - 30px);
    margin: 20px auto;
  }

  .sign-up-complete-container .sticky-menu {
    width: 100%;
    overflow-x: auto;
  }

  .sign-up-complete-container .form-header-description {
    margin-top: 15px;
  }
}
