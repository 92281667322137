.search-form {
  display: inline-block;
  margin-left: 20px;
  vertical-align: top;
  padding: 4px 0;
}

.search-bar {
  position: relative;
  display: inline-block;
}

.search-bar input {
  opacity: 0; 
  padding-left: 0;
  width: 30px;
  transition: opacity .3s;
  border-radius: 15px; 
  border: none;
  background-color: #f9f9f9;
  outline: none;
}

.search-bar.opened input {
  width: 320px;
  height: 36px;
  padding-left: 40px;
  padding-right: 40px;
  opacity: 1;
  transition: all .4s;
}

.search-bar .btn-search {
  position: absolute;
  top: 2px; 
  padding: 5px;
  left: 8px;
  cursor: pointer;
}

.search-bar .submit-overlay {
  position: absolute; 
  top: 0; 
  left: 0;
  cursor: pointer;
  z-index: -1;
  opacity: 0;
}

.search-bar.opened .submit-overlay {
  width: 40px; 
  height: 35px;
  z-index: 1;
}

.search-bar .btn-remove {
  position: absolute;
  top: 0;
  right: 7px;
  cursor: pointer;
  opacity: 0;
  padding: 5px;
}

.search-bar.opened .btn-remove {
  opacity: 1;
}
