@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(/font/Roboto.woff2) format('woff2');
  unicode-range: U + 0000-00ff, U + 0131, U + 0152-0153, U + 02bb-02bc, U + 02c6, U + 02da, U + 02dc,
    U + 2000-206f, U + 2074, U + 20ac, U + 2122, U + 2191, U + 2193, U + 2212, U + 2215, U + FEFF,
    U + FFFD;
}

html,
#root {
  width: 100%;
  height: 100%;
  --active-blue-color: #0095ff;
  --active-blue-color-hover: #0084e2;
  --danger-red-color: #fe374f;
  --common-text-color: #4d4d4d;
  --orange-color: #f79824;
}

.popup-container .popup-overlay {
  background: none !important;
}

.popup-container .popup-content {
  border-radius: 4px;
  box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.18);
  border: none !important;
  text-align: left;
  font-size: 16px;
}

#modal-root .popup-overlay {
  background: rgba(209, 236, 255, 0.18) !important;
  z-index: 3000 !important;
  overflow: auto;
}

#modal-root .popup-content .popup-overlay {
  background: none !important;
}

#modal-root .popup-content:not(.im-tooltip) {
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  border: none !important;
}

#modal-root .popup-content form button[type='submit'] {
  width: 162px;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto;
  font-size: 16px;
  color: var(--common-text-color);
}

.page-header,
.form-header,
.section-header {
  color: black;
}

body .page-header {
  font-size: 28px;
}
body .form-header {
  font-size: 24px;
}
.popup-content .form-header {
  margin-bottom: 24px;
}
body .section-header {
  font-size: 20px;
}

textarea {
  padding: 14px;
}

.section-header-2 {
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 20px;
}

.example-description {
  font-style: italic;
}

.muted-button {
  border-radius: 4px;
  background-color: #f4f4f4;
  border: solid 1px #dfdfdf;
  opacity: 0.5;
  color: #4d4d4d;
}

.blue-color {
  color: var(--active-blue-color);
}

.danger {
  color: var(--danger-red-color);
}

body .icon-container {
  margin-right: 16px;
  display: inline;
}

.section-separator {
  width: 100%;
  height: 1px;
  margin-bottom: 22px;
  opacity: 0.08;
  border-top: solid 1px #000000;
}

.section-separator.with-vertical-margin-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

body .form-group {
  margin-bottom: 22px;
}

body .form-group.with-error {
  margin-bottom: -6px;
}

body .form-group.with-error label {
  color: #fe374f;
}

body .form-group .description {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #959595;
  margin-bottom: 12px;
}

body .form-group.group-header,
body .form-group .group-header {
  font-size: 18px;
}

body label.error {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fe374f;
}

body label.success {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: green;
}

.image-preview-container {
  position: relative;
  display: inline-block;
}

.image-preview-remove {
  position: absolute;
  line-height: 0;
  top: -6px;
  right: -6px;
  cursor: pointer;
}

.sticky-menu .item {
  padding: 8px 17px;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 1px solid transparent;
}

.dashboard-top-menu .item {
  padding: 10px 16px;
  margin-right: 50px;
  cursor: pointer;
  white-space: nowrap;
}

.dashboard-top-menu .active,
.sticky-menu .active {
  color: var(--active-blue-color) !important;
  border-bottom: solid 1px var(--active-blue-color);
}

.im-tooltip {
  display: flex;
  justify-content: center;
  font-size: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08) !important;
  background-color: #f4f4f4 !important;
  border: solid 1px #dfdfdf !important;
}

.letter-circle-container {
  border-radius: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-image: linear-gradient(to bottom, #696969, #000000);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  font-weight: bold;
  margin-right: 10px;
  color: white;
}

.description-uppercase-small {
  font-size: 8px;
  text-transform: uppercase;
}

.error-top {
  font-size: 12px;
  font-weight: 500;
  color: #fe374f;
}

.modal-email-body-textarea {
  width: 100%;
  height: 158px;
}

.sign-in-form-container .divider {
  width: 100%;
  height: 1px;
  background-color: #323232;
  margin: 20px 0;
}

.sign-in-form-container .gray-button {
  width: 100%;
  height: 36px;
}

.sign-in-form-container, .sign-up-form-container {
  position: relative;
}

.link-forgot-password, .link-sign-in {
  text-align: center;
  opacity: 0.5;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  bottom: -38px;
  width: 100%;
  padding-right: 60px;
  cursor: pointer;
}

.link-forgot-password:hover, .link-forgot-password:focus {
  text-decoration: underline;
}

.link-sign-in {
  bottom: -65px;
  color: #fff;
  line-height: 22px;
}

.link-sign-in:hover {
  color: #fff;
}

.spinner-loading-container {
  position: absolute;
  left: 50%;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  top: 25px;
  z-index: 999999;
}

.spinner-loading-container-embed {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

.centered-loading-spinner.spinner-loading-container {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

.sign-container {
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at 51% 2%, #080808, #000000);
  display: flex;
  align-items: center;
  color: white;
  padding: 30px;
}

.sign-container .content {
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #0b0b0b, #000000);
  border: solid 1px #202020;
  margin-left: auto;
  margin-right: auto;
}

.sign-container .form-black-container {
  padding: 30px;
  width: 340px;
}

.sign-container .form-black-container .form-header {
  color: #ffffff;
}

.sign-container .logo {
  width: 44px;
  height: 44px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -22px;
  background-image: url('/img/logo.svg');
}

.sign-container .form-header {
  padding-bottom: 25px;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.sign-container button[type='submit'] {
  width: 100%;
  height: 36px;
  margin-top: 9px;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #39b54a, #34aa44 98%);
  background-color: #34aa44;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.sign-container button[type='submit']:hover,
.sign-container button[type='submit']:focus,
.sign-container button[type='submit']:active {
  background-image: none;
  background-color: #088419;
}

.sign-container .form-control {
  height: 36px;
  border-radius: 4px;
  background-color: #0f0f0f;
  border: solid 1px #2f2f2f;
  color: #ffffff;
  font-size: 14px;
}

.sign-container .form-control:focus {
  border: solid 1px #888888;
  box-shadow: none;
}

.sign-container label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.sign-container .success.common,
.sign-container .error.common {
  margin-bottom: 0;
  margin-top: -12px;
  position: absolute;
}

.sign-container .image-preview-container {
  margin-top: 14px;
}

.sign-container .user-type-column button {
  width: 100%;
  background-color: #000;
  color: #fff;
  border-color: #fff;
  font-size: 14px;
}

.sign-up-complete-container.content-blur {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

.popup-signup-just-done * {
  pointer-events: auto;
}

.popup-signup-just-done .sign-container {
  padding: 0;
  color: white;
}

.popup-signup-just-done .sign-container .form-header {
  margin-top: 15px;
  padding: 0 25px;
  color: white;
}

.popup-signup-just-done .sign-container .content {
  text-align: center;
  padding: 45px 30px 8px;
}

.popup-signup-just-done .sign-container .logo {
  margin-top: -65px;
}

.popup-signup-just-done .sign-container button {
  width: 100%;
}

#modal-root .popup-content.popup-signup-just-done {
  padding: 0 !important;
}

.sticky-menu {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 901;
}
.products-container .btn-top-right.add-product {
  width: 139px;
}

form .quantities-section .row:not(:last-child) > .form-group {
  margin-bottom: 65px;
}

form .quantities-section .row:not(:last-child) > .form-group .section-header-description {
  margin-bottom: 0;
}


.modal-product-get-quote-link .link svg {
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
  top: -2px;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.upload-button-container {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.hidden-upload-button-file {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.quote-container {
  width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.dashboard-container .product-quote .table tr {
  height: auto;
  padding: 10px 0;
}

.product-quote .table tr td {
  font-size: 14px;
}

.product-quote .table tr td,
.product-quote .table thead th {
  display: inline-block;
  width: 16.66%;
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  padding: 35px 12px;
}

.product-quote .table tr td:first-child,
.product-quote .table thead th:first-child {
  padding-left: 0;
}

.product-quote .table thead th {
  border-bottom: none;
  padding: 22px 12px;
  font-weight: bold;
  color: black;
}

.product-quote .table tr td:last-child {
  text-align: left;
}

.product-quote .quote-creator-details {
  margin-bottom: 30px;
}

.quote-creator-details {
  display: flex;
  align-items: center;
}

.quote-creator-details .name-address {
  margin-right: 60px;
}

.quote-creator-details .name-address .name {
  font-size: 20px;
  font-weight: 500;
}

.quote-creator-details .btn {
  min-width: 174px;
}

.important-mark-note {
  font-size: 10px;
}

.important-mark {
  font-size: 13px;
  color: var(--active-blue-color);
}

.target-mark {
  font-size: 13px;
  color: var(--orange-color);
}

.quote-details {
  padding: 30px 0;
}

.quote-details-value {
  color: black;
  font-weight: 600;
}

.quote-details .row {
  margin: 18px -15px;
}

.product-quote .quote-details .section-header {
  margin: 50px 0 20px;
}

.product-quote .quote-details .section-header-description {
  margin-bottom: 20px;
}

.product-quote .quote-details .section-question {
  margin-top: 30px;
}

.shipped-to {
  font-size: 14px;
  line-height: 1.64;
}

.gray-note {
  opacity: 0.6;
  font-size: 14px;
}

.product-quotes-container {
  width: 96%;
  max-width: 1090px;
  margin-left: auto;
  margin-right: auto;
}

.product-quote-container {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.product-quotes-header h3 {
  font-size: 24px; 
  color: black;
  font-weight: bold;
  margin: 10px 0 20px;
}

.heading-label {
  color: var(--common-text-color); 
  font-size: 12px; 
  text-transform: uppercase;
}

.heading-link {
  color: var(--active-blue-color);
  cursor: pointer;  
}

.product-description {
  display: inline-flex;
  margin: 30px 0;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  padding: 14px 0;
  white-space: nowrap;
  width: 100%;
  max-width: 750px;
}

.product-description .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0;
}

.product-description .col:last-child {
  padding-right: 0;
  margin-right: 0;
}

.product-description .product-photo {
  max-width: 100px;
}

.product-description .date {
  opacity: .6;
  font-size: 12px;
}

.product-description .btn {
  min-width: 174px;
}

.add-to-favorites {
  padding: 3px 4px 5px;
  margin: 0 -3px;
  cursor: pointer;
  position: relative;
  top: -.2rem;
}

.product-quotes-container .table {
  margin: 15px 0;
}

.dashboard-container .product-quotes-container .table-container .table td {
  font-size: 15px;
}

.dashboard-container .product-quotes-container .table-container tr td:first-child {
  padding-left: 30px;
}

.product-quotes-container .table-container .table tr {
  position: relative;
  height: 115px;
}
.table-container .table-link {
  position: absolute; 
  width: 100%; left: 0; top: 0; height: 100%;
}


.search-form {
  display: inline-block;
  margin-left: 20px;
  vertical-align: top;
  padding: 4px 0;
}

.search-bar {
  position: relative;
  display: inline-block;
}

.search-bar input {
  opacity: 0; 
  padding-left: 0;
  width: 30px;
  transition: opacity .3s;
  border-radius: 15px; 
  border: none;
  background-color: #f9f9f9;
  outline: none;
}

.search-bar.opened input {
  width: 320px;
  height: 36px;
  padding-left: 40px;
  padding-right: 40px;
  opacity: 1;
  transition: all .4s;
}

.search-bar .btn-search {
  position: absolute;
  top: 2px; 
  padding: 5px;
  left: 8px;
  cursor: pointer;
}

.search-bar .submit-overlay {
  position: absolute; 
  top: 0; 
  left: 0;
  cursor: pointer;
  z-index: -1;
  opacity: 0;
}

.search-bar.opened .submit-overlay {
  width: 40px; 
  height: 35px;
  z-index: 1;
}

.search-bar .btn-remove {
  position: absolute;
  top: 0;
  right: 7px;
  cursor: pointer;
  opacity: 0;
  padding: 5px;
}

.search-bar.opened .btn-remove {
  opacity: 1;
}

.pagination {
  display: flex;
  padding: 20px 0;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
}

.pagination-btn {
  cursor: pointer;
  padding: 6px;
  display: inline-block;
  margin: 0 16px;
}

.pagination-btn-number {
  display: inline-block;
  color: var(--common-text-color);
  padding: 6px;
  margin: 0 3px;
  margin-top: 4px;
  cursor: pointer;
}

.pagination-btn-number.active {
  pointer-events: none;
  color: var(--active-blue-color);
  text-decoration: underline;
}

.pagination-btn-number.pagination-space-string {
  cursor: default;
}

.project-container {
  width: 100%;
}

.project-container .add-project-from-container {
  margin-top: 64px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.project-container .add-product-from-container {
  margin-top: 64px;
  width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.project-container .table.actions-first-column thead th:last-child {
  width: 250px;
}

.project-container .table.actions-first-column thead th:first-child {
  width: 70px;
}

.project-settings-users-container form {
  min-height: 300px;
  max-width: 700px;
  padding-bottom: 50px;
  position: relative;
}

.dashboard-container .project-settings-users-container form .actions-bottom {
  border-top: none;
  padding-right: 0;
}

.project-settings-users-container .project-users-table input[type='checkbox'] {
  position: relative;
}

.project-settings-users-container input[type='checkbox'].removed-item:after {
  content: '-';
  color: white;
  width: 14px;
  height: 14px;
  line-height: 14px;
  position: absolute;
  z-index: 600;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: visible;
  background-color: var(--danger-red-color);
}

.quote-request-form .react-datepicker-wrapper input {
  padding-left: 35px;
}
.dashboard-container form.quote-request-form textarea {
  height: 170px;
}

.form-group.with-datepicker {
  position: relative;
  z-index: 1400;
}

.quote-form-container {
  position: relative;
  padding-top: 64px;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
}

.quote-form-section-header-container {
  margin-bottom: 80px;
}

.product-photos-row-photo-container {
  width: 130px;
  padding: 0 15px;
  margin-right: 10px;
  margin-bottom: 60px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .quote-form-container {
    width: 100%;
    padding: 60px 15px 30px;
    font-size: 0.95rem;
  }

  .quote-form-section-header-container {
    margin-bottom: 30px;
  }

  .quote-form-section-header-container img {
    margin: 15px 0 25px;
  }

  .product-photos-row-photo-container {
    width: 110px;
    margin-right: 15px;
    margin-bottom: 30px;
  }

  .dashboard-container .sticky-menu {
    top: 0;
    left: 0;
    width: 100%;
    overflow-x: auto;
    z-index: 1111;
    height: auto;
    -webkit-backface-visibility: hidden;
    font-size: 0.9rem;
  }

  .dashboard-container .sticky-menu .item {
    padding: 9px 15px;
  }

  .quote-form-container fieldset .row {
    align-items: flex-end;
  }

  .dashboard-container form.padding-bottom-180 {
    padding-bottom: 110px;
  }

  .landing-quote-form .properties-row.quantities-row {
    padding: 18px 10px;
  }

  .landing-quote-form .quantities-row .form-group {
    padding: 0 14px;
  }

  .landing-quote-form .quantities-row .form-group .form-group {
    padding: 0;
  }

  .landing-quote-form .photo-thumbnail {
    margin: 10px 15px 20px 0;
    width: 70px;
  }

  .landing-quote-form .target-price-value {
    padding: 0;
    margin: -50px 0 -30px;
  }
  .landing-quote-form p {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 500px) {
  .dashboard-container .sticky-menu {
    font-size: 0.8rem;
  }

  .dashboard-container .sticky-menu .item {
    padding: 8px 10px;
  }
}

.dashboard-container {
  position: relative;
}

.dashboard-container .content {
  width: 100%;
  padding: 36px 50px 36px 300px;
}

.dashboard-container .content.hide-left-menu {
  padding: 36px 50px;
}

.dashboard-container .content-blur {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

.dashboard-container .dashboard-top-menu {
  border-bottom: solid 1px #d9d9d9;
  height: 40px;
  padding-top: 8px;
  margin-bottom: 60px;
}

.dashboard-container .page-header {
  margin-bottom: 26px;
}

.dashboard-container .section-header {
  margin-bottom: 32px;
}

.dashboard-container .form-group label {
  color: black;
}

.dashboard-container .form-group label.error.custom {
  color: #fe374f;
}

.dashboard-container {
  --table-border-color: #e9e9e9;
}

.dashboard-container .table .action {
  padding: 3px 0;
}

.dashboard-container .table tr {
  height: 83px;
}

.dashboard-container .table td {
  font-size: 18px;
}

.dashboard-container .table td,
.dashboard-container .table th {
  padding: 0;
  vertical-align: middle;
  border-bottom: 1px solid var(--table-border-color);
}

.dashboard-container .table td:last-child {
  text-align: center;
}

.dashboard-container .table thead th {
  font-size: 14px;
  font-weight: bold;
  color: #3a3a3a;
  border-top: none;
  padding-left: 5px;
  padding-right: 5px;
}

.dashboard-container .table thead th:last-child {
  width: 72px;
}

.dashboard-container .table.actions-first-column td:last-child {
  text-align: left;
}

.dashboard-container .table.black-table thead th {
  color: #080513;
  border-top: none;
}

.dashboard-container .table .popup-content {
  box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.18);
  border-radius: 4px;
}

.dashboard-container .triangle-right {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 7px solid white;
  border-bottom: 7px solid transparent;
}

.dashboard-container .triangle-top {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
}

.dashboard-container form .actions-bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  bottom: -65px;
  padding: 14px;
  border-top: solid 1px #d9d9d9;
  margin-top: 168px;
}

.dashboard-container form .actions-bottom-centered {
  display: flex;
  justify-content: center;
  border: none;
  padding: 0 0 40px;
}

.dashboard-container form .actions-bottom > * {
  margin-left: 16px;
}

.dashboard-container form .actions-bottom button {
  width: 174px;
  height: 36px;
}

.dashboard-container form .actions-bottom .wide-button {
  width: auto;
  padding: 0 30px;
}

.dashboard-container form .section-header {
  padding-top: 25px;
  margin-top: 40px;
}

.dashboard-container form.padding-bottom-180 {
  padding-bottom: 180px;
}

.dashboard-container .form-header {
  margin-bottom: 13px;
}

.dashboard-container .form-header-icon {
  margin-right: 10px;
}
.dashboard-container .form-header-description {
  margin-bottom: 75px;
  line-height: 1.81;
}
.dashboard-container .section-header-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 36px;
  width: 100%;
}

.dashboard-container .section-question {
  font-size: 16px;
  color: black;
  font-weight: 500;
}

.dashboard-container .section-answer {
  padding: 10px 0;
  font-size: 18px;
}

.dashboard-container .sticky-menu {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: solid 1px #d9d9d9;
  height: 41px;
}

.dashboard-container form textarea {
  width: 100%;
  height: 100px;
}

.dashboard-container .icon-close-container {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 50px;
  padding: 3px;
}

.dashboard-container button .icon {
  margin-right: 9px;
  display: inline-block;
  vertical-align: text-bottom;
}

.dashboard-container form .photos-container {
  margin-top: 12px;
}

.dashboard-container form .image-preview-container {
  margin-right: 19px;
}

.dashboard-container form .img-thumbnail {
  padding: 0;
  border-radius: unset;
  background-color: #f4f3f3;
  border: solid 1px #efefef;
}

.dashboard-container .form-control::-webkit-input-placeholder {
  opacity: 0.5;
}

.dashboard-container .form-control:-ms-input-placeholder {
  opacity: 0.5;
}

.dashboard-container .form-control::-ms-input-placeholder {
  opacity: 0.5;
}

.dashboard-container .form-control::placeholder {
  opacity: 0.5;
}

.dashboard-container .muted-text {
  opacity: 0.6;
}

.dashboard-container .description-text {
  font-size: 12px;
}

.dashboard-container .important-text {
  font-weight: bold;
  color: #262626;
}

.dashboard-container .danger-text {
  color: var(--danger-red-color);
}

.form-group.no-margin-bottom {
  margin-bottom: 0;
}

.form-group.half-margin-bottom {
  margin-bottom: 12px;
}

.form-group .link {
  color: var(--active-blue-color);
  cursor: pointer;
}

.dashboard-container .table a {
  color: var(--common-text-color);
  cursor: pointer;
  transition: color 0.3s;
}

.dashboard-container .table a:hover {
  color: var(--active-blue-color);
  outline: none;
}

.dashboard-container .popup-content .icon-close-container {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  transition: 0.35s;
  opacity: 0.8;
}

.dashboard-container .popup-content .icon-close-container:hover,
.dashboard-container .popup-content .icon-close-container:focus {
  opacity: 1;
}

.dashboard-container .form-group .form-value {
  font-size: 18px;
}

.dashboard-container .form-value.vertically-centered {
  line-height: 2;
}

.dashboard-container .table-container .table td {
  font-size: 16px;
  padding: 20px 5px;
  line-height: 2;
  max-width: 280px;
}

.dashboard-container .table-container .table tbody tr:hover {
  background-color: #f9f9f9;
}

.dashboard-container .table-container .table thead tr {
  height: 52px;
}

.dashboard-container .table-container .table th:first-child,
.dashboard-container .table-container .table td:first-child {
  padding-left: 12px;
}

.dashboard-container .table-container .table td.top-baseline {
  padding-top: 40px;
  vertical-align: top;
}

.dashboard-container .label-block-blue {
  border-radius: 4px;
  color: white;
  background-color: var(--active-blue-color);
  border: solid 1px #038bec;
  padding: 6px 18px;
  margin-right: 24px;
}

.dashboard-container > .spinner-loading-container {
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -10px 0 0 -10px;
}

@media screen and (max-width: 768px) {
  .dashboard-container form .actions-bottom {
    justify-content: center;
    padding: 18px 0;
    margin-top: 40px;
  }

  .dashboard-container form .actions-bottom > * {
    margin: 0;
  }

  .dashboard-container form .actions-bottom .wide-button {
    width: 100%;
    max-width: 290px;
    font-size: 0.95em;
  }

  .dashboard-container form .section-header {
    margin: 15px 0 20px;
  }

  .dashboard-container .section-header-description {
    font-size: inherit;
    margin-bottom: 25px;
  }

  .dashboard-container .landing-quote-form-container form {
    padding-bottom: 35px;
  }
}

.dashboard-container .left-menu {
  width: 250px;
  height: 100%;
  object-fit: contain;
  background-color: #080513;
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
}

.dashboard-container .left-menu .sub-menu-items-container.projects {
  max-height: 448px;
  background-color: #007dd7;
  padding: 4px 0;
  position: relative;
}

.dashboard-container .left-menu .sub-menu-items-container.projects.fixed {
  height: 448px;
}

.dashboard-container .dashboard-left-menu .menu-header {
  font-size: 19.8px;
  font-weight: 500;
  letter-spacing: 3.3px;
  color: #ffffff;
  padding: 36px 0 60px 15px;
}

.dashboard-container .dashboard-left-menu .menu-header label {
  margin-left: 10px;
  vertical-align: text-top;
  line-height: 26px;
}

.dashboard-container .dashboard-left-menu .menu-main-section span.text {
  margin-left: 12px;
}

.dashboard-container .dashboard-left-menu .menu-main-section .active {
  color: white;
  pointer-events: none;
}

.dashboard-container .dashboard-left-menu .menu-main-section-header {
  font-size: 10px;
  font-weight: bold;
  padding: 15px 25px 3px;
}

.dashboard-container .dashboard-left-menu .menu-item {
  position: relative;
  height: 50px;
  padding: 14px 25px;
  width: 100%;
  cursor: pointer;
  transition: all 0.35s;
}

.dashboard-container .dashboard-left-menu .menu-item:hover {
  background-color: rgba(28, 28, 28, 0.7);
}
.dashboard-container .dashboard-left-menu .menu-main-section .menu-item:hover {
  color: #a1a1a1;
}

.dashboard-container .dashboard-left-menu .sub-menu .menu-item {
  height: 39px;
  padding: 8px 24px;
  line-height: 1.44;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}

.dashboard-container .dashboard-left-menu .sub-menu.account .menu-item {
  padding: 12px 15px;
  font-size: 14px;
}

.dashboard-container .dashboard-left-menu .menu-item.projects {
  background-color: var(--active-blue-color);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.dashboard-container .dashboard-left-menu .menu-item.account {
  border-radius: 4px;
  margin: 15px;
  width: 220px;
  background-color: #262626;
  color: white;
  display: flex;
  align-items: center;
  padding: 14px 15px;
  cursor: pointer;
  position: absolute !important;
  bottom: 0;
}

.dashboard-container .dashboard-left-menu .menu-item.account:hover {
  background-color: #1c1c1c;
}

.dashboard-container .dashboard-left-menu .sub-menu {
  background-color: var(--active-blue-color);
  color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dashboard-container .dashboard-left-menu .sub-menu.account {
  color: white;
  border-radius: 4px;
  background-color: #262626;
}

.dashboard-container .dashboard-left-menu .sub-menu .menu-item.add-new-project {
  height: 66px;
}

.dashboard-container .dashboard-left-menu .sub-menu .menu-item label {
  margin-left: 17px;
  margin-bottom: 0;
  cursor: pointer;
}

.dashboard-left-menu .menu-item .indicator-new-messages {
  top: calc(50% - 7px);
  margin: 0;
  right: 25px;
  width: 14px;
  height: 14px;
}

.dashboard-left-menu .menu-item .counter-new-messages {
  width: 30px;
  height: 20px;
  font-size: 14px;
}

.dashboard-left-menu .sub-menu-items-container.projects .pagination {
  justify-content: flex-start;
  padding: 6px 18px 13px 18px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pagination-btn {
  margin: 0;
}

.dashboard-left-menu .sub-menu-items-container.projects .menu-item:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.dashboard-left-menu .sub-menu-items-container.projects .pagination-btn-number {
  color: white;
  padding: 6px 4px;
}

.dashboard-left-menu .sub-menu-items-container.projects .pagination-btn-number.active {
  pointer-events: none;
  color: white;
  font-weight: bold;
}

.dashboard-left-menu .menu-projects-search-container {
  position: relative;
}

.dashboard-left-menu .menu-projects-search-container input {
  border: none;
  outline: none;
  width: 245px;
  height: 32px;
  border-radius: 17px;
  background-color: #007dd7;
  margin: 14px 15px;
  padding: 0 32px;
  color: #fff;
}

.dashboard-left-menu .menu-projects-search-container input::-webkit-input-placeholder {
  color: #fff;
  opacity: 0.6;
}

.dashboard-left-menu .menu-projects-search-container input:-ms-input-placeholder {
  color: #fff;
  opacity: 0.6;
}

.dashboard-left-menu .menu-projects-search-container input::-ms-input-placeholder {
  color: #fff;
  opacity: 0.6;
}

.dashboard-left-menu .menu-projects-search-container input::placeholder {
  color: #fff;
  opacity: 0.6;
}

.dashboard-left-menu .menu-projects-search-container .search-icon-container {
  position: absolute;
  left: 22px;
  top: 18px;
}

.dashboard-left-menu .menu-projects-search-container .search-clear-icon-container {
  position: absolute;
  left: 26px;
  top: 18px;
  cursor: pointer;
}

.messages-container {
  display: flex;
  min-height: 100vh;
  background-color: #fbfbfb;
}

.messages-chat-rooms {
  height: 100vh;
  overflow: auto;
  width: 33.33%;
  border-right: solid 1px #dddddd;
}

.messages-feed {
  height: 100vh;
  overflow: auto;
  width: 66.66%;
}

.messages-feed .messages-heading {
  padding: 18px 34px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
}

.messages-feed .messages-heading .white-button {
  width: 174px;
  margin-left: 30px;
}

.messages-heading {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  min-height: 148px;
  padding: 35px 24px 22px;
  background-color: #fff;
  border-bottom: 1px solid #e3e8ef;
}

.messages-heading h2 {
  color: #080513;
  font-size: 24px;
}

.messages-heading h3 {
  font-size: 18px;
}

.messages-heading .search-form {
  margin-left: 0;
  height: 40px;
  padding: 0;
}

.messages-heading .messages-filters-search-container {
  width: 100%;
  margin-top: 30px;
}

.messages-heading .messages-filters-search-separator-container {
  width: 14px;
  height: 22px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
}

.messages-heading .messages-filters-search-separator {
  border-left: 2px solid #d9d9d9;
  width: 2px;
  height: 20px;
  top: 9px;
  left: 11px;
  position: absolute;
}

.messages-heading .search-bar {
  width: 100%;
}

.messages-heading .search-bar.opened input {
  width: 100%;
}

.messages-heading .date {
  font-size: 12px;
  color: #4d4d4d;
}

.messages-heading .search-bar .btn-search {
  left: 0;
  transition: left 0.4s;
}

.messages-heading .search-bar.opened .btn-search {
  left: 8px;
}

.messages-container {
  margin: -36px -50px;
}

.messages-heading-button {
  display: flex;
  align-self: flex-end;
  padding: 15px 0;
}

.messages-heading-details {
  width: 100%;
  position: relative;
  padding: 0 250px 0 14px;
}

.messages-heading-details .white-button {
  position: absolute;
  right: 0;
  bottom: 10px;
}

.chat-preview {
  position: relative;
  padding: 26px 18px 18px 27px;
  border-bottom: 1px solid #e3e8ef;
  border-left: 5px solid transparent;
  transition: all 0.35s;
  cursor: pointer;
}

.chat-preview.active {
  border-left-color: #0095ff;
  pointer-events: none;
}

.chat-preview .product-name {
  margin-top: 12px;
}

.chat-heading {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-right: 65px;
}

.chat-heading .time-stamp {
  position: absolute;
  top: 3px;
  right: 0;
  font-size: 14px;
  color: #7f8fa4;
}

.chat-heading .company-name {
  font-size: 16px;
  color: #4d4d4d;
}

.chat-heading .contact-name {
  font-size: 14px;
  color: #7f8fa4;
}

.chat-heading .heading-with-names {
  width: 100%;
}
.chat-heading .company-name,
.chat-heading .contact-name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 60px);
  white-space: nowrap;
}

.indicator-new-messages {
  position: absolute;
  top: 50%;
  right: 18px;
  margin-top: -12px;
  width: 24px;
  height: 24px;
  border-radius: 31px;
  background-image: linear-gradient(to bottom, #fcc94a, #f79824);
}

.counter-new-messages {
  width: 34px;
  height: 24px;
  border-radius: 15px;
  background-color: #f79824;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  top: calc(50% - 8px);
  font-size: 16px;
  color: white;
}

.messages-items {
  display: flex;
  min-height: calc(100vh - 260px);
}

.messages-list-items {
  flex-direction: column;
}

.messages-items .message {
  position: relative;
  padding: 25px 75px 25px 35px;
}

.messages-feed .send-message-form {
  position: -webkit-sticky;
  position: sticky;
  bottom: 25px;
  box-shadow: 0 10px 15px 20px rgba(255, 255, 255, 0.6);
}

.send-message-form {
  display: flex;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #dfe3e9;
  margin: 25px 34px;
}

.message-attach-files-container {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 70px;
  transition: all 0.35s;
}

.message-attach-files-container:hover {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

.hidden-attach-files-input {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.message-attach-files-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 17px 15px;
  width: 64px;
  height: 56px;
  border-radius: 0;
  border-right: 1px solid #dfe3e9;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  cursor: pointer;
}

.messages-container .send-message-form .message-text-input {
  height: 100%;
  width: 100%;
  padding: 16px 12px;
  resize: none;
  border: none;
  outline: none;
  white-space: pre;
  font-size: 14px;
}

.message-quote-table {
  width: 100%;
  border: solid 1px #f3f3f3;
  font-size: 14px;
  margin-top: 15px;
}

.message-quote-table th {
  padding: 22px 20px 10px 24px;
  color: black;
  font-weight: 600;
}

.message-quote-table td {
  padding: 10px 20px 10px 24px;
}

.message-quote-table .white-button {
  width: 100%;
}

.message-quote-table .product-name {
  font-size: 16px;
  color: #0095ff;
}

.message-quote-table .quoted-date {
  font-size: 12px;
  color: var(--common-text-color);
  opacity: 0.6;
}

.message-quote-table .target-price {
  font-size: 16px;
  color: var(--common-text-color);
}

.message-quote-table .quantity-mark {
  font-size: 12px;
  padding: 24px;
  color: var(--common-text-color);
}

.message-quote-table .target-price-quantity-mark {
  padding-top: 0;
}

.chat-message {
  font-size: 14px;
  line-height: 1.57;
  color: #354052;
  margin: 11px 0 0;
  padding-left: 66px;
  line-height: 1.71;
}

.chat-message.attachment {
  font-size: 20px;
}

.heading-mark {
  margin: 20px 0 30px;
  border-bottom: 1px solid #e3e8ef;
  text-align: center;
}

.heading-mark .mark-text {
  display: inline-block;
  position: relative;
  top: 0.9em;
  padding: 0 15px;
  color: #7f8fa4;
  background-color: #fafafa;
  font-size: 14px;
}

.heading-mark.new-messages {
  border-bottom: 1px solid #f8a32c;
}

.heading-mark.new-messages .mark-text {
  color: #f8a32c;
}

.modal-company-switch .section-separator,
.modal-company-switch .form-group {
  margin-bottom: 13px;
}
.company-choose-container .letter-circle-container {
  float: left;
}

.company-choose-container .form-description {
  margin-bottom: 34px;
}

.company-choose-container .company-item {
  cursor: pointer;
  margin-bottom: 16px;
}

.company-choose-container .company-item.selected {
  background-color: #0095ff;
  border: solid 1px #0095ff;
}

.black-page-container .logo-container {
  width: 44px;
  height: 44px;
  position: absolute;
  left: 148px;
  top: -22px;
}

.black-page-container {
  color: white;
}

.black-page-container .form-header {
  text-align: center;
}

.black-page-container .page-header,
.black-page-container .form-header,
.black-page-container .section-header {
  color: white;
}

.black-page-container .form-description {
  opacity: 0.69;
  line-height: 1.5;
  text-align: center;
  color: #ffffff;
}

.black-page-container .company-item {
  border-radius: 4px;
  border: solid 1px #2b2b2b;
  padding: 10px 16px;
  font-size: 14px;
}

.black-page-container button[type='submit'] {
  width: 100%;
  height: 36px;
  margin-top: 40px;
  border: none;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #39b54a, #34aa44 98%);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.popup-invite-users {
  width: 800px !important;
}

.popup-invite-users .form-group {
  margin-bottom: 0;
}

.popup-invite-users textarea {
  width: 100%;
  height: 100px;
}

.popup-invite-users button {
  margin-top: 42px;
  width: 162px;
}

.popup-edit-user-role {
  width: 1092px !important;
  height: 500px;
}

.popup-edit-user-role button[type='button'] {
  width: 100%;
}

.popup-edit-user-role button[type='submit'] {
  float: right;
  width: 162px;
  margin-top: 47px;
}

.popup-edit-user-role .blue-button.border-x2 {
  font-size: 14px;
}

.popup-user-owner-transfer-confirm-content {
  text-align: center;
}

.popup-user-owner-transfer-confirm-content button {
  font-size: 16px;
  font-weight: bold;
  width: 220px;
}
.company-edit-form .upload-button-container {
  display: block;
  margin-bottom: 22px;
}
.market-buttons button {
  margin-right: 12px;
  font-size: 16px;
}

.market-buttons button.blue-button {
  font-size: 16px;
}
.sign-up-complete-container {
  margin-top: 100px;
  width: 720px;
  margin-left: auto;
  margin-right: auto;
}
.sign-up-complete-container .form-header {
  font-size: 24px;
}

.sign-up-complete-container .form-header-icon {
  margin-right: 10px;
}
.sign-up-complete-container .form-header-description {
  margin-bottom: 40px;
}

.sign-up-complete-container .section-header {
  font-size: 20px;
  padding: 65px 0 30px;
}

.sign-up-complete-container .buttons-bottom {
  border-top: solid 1px #d9d9d9;
}

.sign-up-complete-container button[type='submit'] {
  width: 174px;
  float: right;
}

.sign-up-complete-container fieldset {
  padding-bottom: 50px;
  border-bottom: solid 1px #d9d9d9;
}

.sign-up-complete-container fieldset.fieldset-last,
.sign-up-complete-container .fieldset-last {
  padding-bottom: 200px;
  border-bottom: 0;
}

.sign-up-complete-container .sticky-menu {
  background-color: #fff;
  border-bottom: solid 1px #d9d9d9;
  height: 41px;
  padding-top: 8px;
}

.sign-up-complete-container .sticky-menu .item {
  padding: 11px 17px;
}

.sign-up-complete-container .photos-container {
  margin-top: 12px;
}

.sign-up-complete-container .image-preview-container {
  margin-right: 19px;
}

.sign-up-complete-container .img-thumbnail {
  padding: 0;
  border-radius: unset;
  background-color: #f4f3f3;
  border: solid 1px #efefef;
}

.sign-up-complete-container .form-sign-up-complete-invited fieldset.fieldset-last,
.sign-up-complete-container .form-sign-up-complete-invited fieldset {
  padding-bottom: 0;
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .sign-up-complete-container {
    width: calc(100% - 30px);
    margin: 20px auto;
  }

  .sign-up-complete-container .sticky-menu {
    width: 100%;
    overflow-x: auto;
  }

  .sign-up-complete-container .form-header-description {
    margin-top: 15px;
  }
}

.importist-app {
    width: 100%;
    height: 100%;
}
.btn-top-right {
  float: right;
  width: 162px;
}

.user-type .user-type-column:not(:last-child) {
  border-right: solid 1px #202020;
}
.user-type-column {
  width: 50%;
  display: inline-block;
  padding: 12px 14px 0;
  cursor: pointer;
}
.user-type-column.selected {
  color: #0095ff;
  cursor: default;
}
.user-type-column label {
  text-align: center;
  margin: 14px 0;
  cursor: inherit;
}
.user-type-column.selected button {
  color: #0095ff;
  border-color: #0095ff;
  cursor: default !important;
}
.user-type-column-icon {
  width: 100%;
  text-align: center;
}
body .btn {
  line-height: 1.5;
  font-size: inherit;
  transition: all 0.5s;
}

body .btn:hover {
  transition-duration: 0.35s;
}

body .btn:disabled {
  pointer-events: none;
}

body .blue-button {
  font-size: 15px;
  color: white;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(41, 42, 43, 0.2);
  background-color: var(--active-blue-color);
  border: solid 1px rgba(206, 206, 206, 0.3);
}

body .blue-button:hover,
body .blue-button:focus,
body .blue-button:active {
  box-shadow: 0 1px 3px 0 rgba(41, 42, 43, 0.1);
  background-color: var(--active-blue-color-hover);
}

body .blue-white-button {
  background-color: white;
  font-size: 14px;
  color: var(--active-blue-color);
  border: solid 1px var(--active-blue-color);
}

body .black-white-button {
  font-size: 14px;
  color: var(--common-text-color);
  background-color: white;
  border: solid 1px var(--common-text-color);
}

body .red-white-button {
  font-size: 14px;
  color: var(--danger-red-color);
  border: solid 1px var(--danger-red-color);
}

body .danger-button {
  font-size: 14px;
  background-image: linear-gradient(to left, #ab213e, #fe374f);
  color: white;
}

body .disabled-button {
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px var(--common-text-color);
}

body .white-button {
  font-size: 15px;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(41, 42, 43, 0.2);
  background-color: #ffffff;
  border: solid 1px rgba(206, 206, 206, 0.3);
  color: var(--common-text-color);
}

body .white-button:hover,
body .white-button:focus,
body .white-button:active {
  box-shadow: 0 1px 1px 0 rgba(41, 42, 43, 0.1);
  border: solid 1px rgba(206, 206, 206, 0.7);
}

body .white-button-small {
  font-size: 12px;
  border-radius: 3px;
  color: #262626;
  border: solid 1px #edeaea;
  background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0.1), rgba(216, 216, 216, 0.1));
}

body .gray-button {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.36;
  color: #ffffff;
  border-radius: 4px;
  background-color: #273142;
  border: solid 1px #313d4f;
}

body .gray-button:hover,
body .gray-button:focus,
body .gray-button:active {
  background-color: #1a2538;
  border-color: #273142;
}

body button .border-x2 {
  border-width: 2px;
}

body .btn-icon {
  margin-right: 11px;
}

.buttons-bottom {
  display: flex;
  justify-content: flex-end;
  padding: 14px 0;
}

.buttons-bottom button {
  width: 162px;
}

.action-link-text {
  font-size: 12px;
  font-weight: 500;
  color: var(--active-blue-color);
  margin-left: 8px;
  cursor: pointer;
}

body .big-button {
  font-size: 19px;
  font-weight: bold;
}

body .fullwidth-button {
  width: 100%;
}

body .uppercased-button {
  text-transform: uppercase;
  font-weight: normal;
}

.filter-label {
  font-size: 12px;
  border-radius: 3px;
  color: #262626;
  border: solid 1px #edeaea;
  background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0.1), rgba(216, 216, 216, 0.1));
  padding: 9px;
  display: inline-block;
}

.language-selector-container-top-right {
  position: absolute;
  top: 15px;
  right: 7%;
  z-index: 1250;
}

@media screen and (max-width: 767px) {
  .language-selector-container-top-right {
    position: absolute;
    top: 22px;
    right: 15px;
  }
}

.language-selector {
  position: relative;
  width: 79px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.form-steps-indicator {
  position: relative;
  width: 475px;
  height: 12px;
  margin: 10px auto 80px;
  background-color: #d8d8d8;
}

.form-steps-indicator .form-steps-fill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: var(--active-blue-color);
  transition: all 0.6s;
}

.form-steps-indicator .form-steps {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -18px;
  display: flex;
  justify-content: space-between;
}

.form-steps .form-step {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 30px;
}

.form-steps .form-step.first-step {
  margin-left: -2px;
}

.form-steps .form-step.last-step {
  margin-right: -2px;
}

.form-step .form-step-dot {
  display: block;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: #d8d8d8;
  transition: color 0.6s;
}

.passed-opened-step .form-step-dot {
  background-color: var(--active-blue-color);
}

.form-step .form-step-name {
  margin-top: 10px;
  color: #d8d8d8;
  font-size: 20px;
  font-weight: bold;
  transition: color 0.6s;
}

.passed-opened-step .form-step-name {
  color: var(--active-blue-color);
}

@media screen and (max-width: 768px) {
  .form-steps-indicator {
    width: 100%;
    height: 9px;
  }

  .form-step .form-step-name {
    font-size: 16px;
  }

  .form-steps-indicator .form-steps {
    margin-top: -16px;
  }

  .form-steps .form-step:nth-child(2) {
    position: relative;
    left: -5px;
  }

  .form-step .form-step-dot {
    width: 30px;
    height: 30px;
  }
}

form.landing-quote-form {
  font-size: 18px;
}

.landing-quote-form .form-step-content {
  margin-top: 60px;
}

.landing-quote-big-heading {
  font-size: 32px;
  color: #000;
  margin: 25px 0 45px;
  line-height: normal;
}

.landing-quote-form .form-steps-indicator {
  margin-top: -15px;
}

.landing-quote-form .big-button {
  font-size: 20px;
  height: 56px;
}

.landing-quote-form .highlighted,
.landing-quote-form .form-group .highlighted {
  color: var(--active-blue-color);
}

.landing-quote-image-and-volume-quotes {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 230px;
}

.landing-quote-volume-quotes {
  display: inline-block;
  margin: 0 80px 0 65px;
  vertical-align: middle;
}

.landing-quote-form .volume-quotes-value {
  font-size: 32px;
  color: black;
  margin: 10px 0;
}

.landing-quote-form .volume-quotes-label {
  font-size: 16px;
  color: #bcbcbc;
}

.landing-quote-form .form-step-content .big-input {
  height: 56px;
  font-size: 16px;
}

.landing-quote-form .form-step-1-content p {
  color: black;
}

.landing-quote-form .form-step-1-content .form-group {
  margin-bottom: 30px;
}

.landing-quote-form .form-step-1-content .form-group.with-error {
  margin-bottom: 0;
}

.landing-quote-form .btn.disabled,
.landing-quote-form .btn:disabled {
  opacity: 0.25;
}

.landing-quote-form .error-top {
  margin: 5px 0;
}

.landing-quote-form .blue-label {
  color: var(--active-blue-color);
}

.landing-quote-form .specification-col {
  padding-left: 60px;
}

.landing-quote-form .specification {
  margin-bottom: 35px;
}

.specificaiton-block-bottom {
  padding: 5px 0 0;
  text-align: center;
}

.specification .specification-trigger {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #000000;
  opacity: 0.2;
  transition: all 0.5s;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  cursor: pointer;
}

.specification .specification-trigger:hover {
  opacity: 0.4;
}

.specification.opened .specification-trigger {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.landing-quote-form .photo-thumbnail {
  display: inline-block;
  cursor: pointer;
}

.landing-quote-form .photo-thumbnail:last-child {
  margin-right: 0;
}

.landing-quote-form .properties-row {
  position: relative;
  padding: 18px 24px;
  border-bottom: solid 1px #f0f0f0;
}

.landing-quote-form .quantities-row {
  padding-bottom: 0;
  border-bottom: none;
}

.landing-quote-form .quantities-row .form-control {
  height: 45px;
  color: black;
  font-size: 18px;
  padding: 10px 20px;
}

.landing-quote-form .properties-row label {
  color: #868686;
  font-size: 16px;
}

.landing-quote-form .properties-row .error {
  font-size: 12px;
}

.landing-quote-form .properties-row .section-header {
  margin: 0;
  color: black;
  font-size: 22px;
}

.landing-quote-form .property-value {
  color: black;
}

.landing-quote-form .properties-row .quote-currency {
  position: absolute;
  bottom: 20px;
  right: 24px;
  color: #868686;
}

.landing-quote-form .target-price-value {
  padding: 10px 20px;
  color: black;
}

.landing-quote-form .required-quantity {
  color: black;
}

.landing-quote-form .form-step-3-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  height: calc(100vh - 250px);
}

.landing-quote-form-c-container .landing-quote-form .form-step-3-content {
  padding-top: 10px;
  height: auto;
}

.landing-quote-form .form-step-3-content h2 {
  font-size: 32px;
  font-weight: bold;
}

.landing-quote-form .form-step-3-content p {
  max-width: 610px;
  margin: 36px 0 48px;
  font-size: 22px;
}

.landing-quote-form .form-step-3-content .wide-button {
  width: 400px;
}

.landing-quote-form ::-webkit-input-placeholder {
  font-style: italic;
}

.landing-quote-form-b .landing-quote-big-heading {
  margin: 50px 160px 35px;
  line-height: normal;
}

.landing-quote-form-b .landing-quote-big-heading.top-heading {
  margin: 0;
  margin: initial;
}

.landing-quote-form-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
}

.landing-quote-form-container form .actions-bottom button {
  height: 56px;
}

.landing-quote-form-c-container .quote-request-form .react-datepicker-wrapper input {
  padding-left: 35px;
}

.landing-quote-form-d-container .landing-quote-form .form-steps-indicator {
  margin: 0 auto 120px;
}

.landing-quote-form-d-container .form-step-content label {
  margin-top: 25px;
}

.landing-quote-form-d-container .form-step-content label.error {
  margin-top: 0;
}

.landing-quote-form-d-container .react-datepicker-wrapper {
  width: 100%;
}

.landing-quote-form-d-container .react-datepicker__input-container {
  width: 100%;
}

.dashboard-container .form-group .error {
  color: #fe374f;
}

.landing-quote-form-d-container .heading-row {
  margin-top: 120px;
}

.landing-quote-form-d-container .images-specification {
  margin-top: -18px;
}

@media screen and (max-width: 1230px) {
  .landing-quote-form-container {
    max-width: 100%;
    padding: 21px 15px;
    font-size: 0.8rem;
  }

  .landing-quote-form-container .language-selector-container-top-right {
    top: 21px;
    right: 13px;
  }

  .landing-quote-form .form-steps-indicator {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    width: 90%;
    max-width: 270px;
    margin: 40px auto;
  }

  .landing-quote-form .form-step-1-content {
    margin-top: 140px;
  }

  .landing-quote-form-c-container .landing-quote-form .form-step-1-content {
    margin-top: 80px;
  }

  .landing-quote-image-and-volume-quotes {
    flex-direction: column;
  }

  .landing-quote-image-and-volume-quotes img {
    margin: 30px 0;
  }

  .landing-quote-big-heading {
    margin: 10px 0 35px;
    font-size: 23px;
  }

  .landing-quote-form .form-step-1-content p {
    font-size: 17px;
  }

  .landing-quote-image-and-volume-quotes {
    margin-bottom: 55px;
  }

  .landing-quote-form .specification-col {
    padding: 0;
  }

  .landing-quote-form .volume-quotes-value {
    font-size: 26px;
    text-align: center;
  }

  .landing-quote-form .form-step-2-content {
    margin-top: 115px;
  }

  .landing-quote-form .form-step-3-content {
    height: auto;
    padding-top: 110px;
  }

  .landing-quote-form .form-step-3-content .wide-button {
    width: auto;
    max-width: 290px;
  }

  .landing-quote-form .big-button {
    font-size: 17px;
  }

  .landing-quote-form .form-step-3-content p {
    font-size: 18px;
  }

  .landing-quote-form .form-step-3-content h2 {
    font-size: 27px;
  }

  .landing-quote-form-b .form-step-content {
    margin-top: 55px;
  }

  .landing-quote-form-b .landing-quote-big-heading {
    margin: 30px 0 25px;
    font-size: 23px;
  }

  .landing-quote-form .properties-row label {
    display: inline-block;
    width: calc(100% - 22px);
    vertical-align: top;
  }

  .landing-quote-form-c-container .form-steps-indicator {
    margin: 30px 0;
  }

  .landing-quote-form-d-container .heading-row {
    margin-top: 30px;
  }

  .landing-quote-form-d-container .images-specification .big-product-image {
    width: 290px;
    height: 290px;
  }

  .landing-quote-form-d-container .landing-quote-form .form-steps-indicator {
    font-size: 16px;
    position: relative;
    margin: 0 auto 120px;
  }
}

.form-steps-tabs-indicator {
  position: relative;
  width: 100%;
  height: 86px;
  margin: 70px auto 50px;
  background-color: #f5f5f5;
}

.form-steps-tabs-indicator .form-steps {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
}

.form-steps-tabs-indicator .form-step {
  position: relative;
  display: flex;
  width: 33.3%;
  align-items: center;
  flex-direction: column;
  align-items: center;
}

.form-steps-tabs-indicator .form-step.first-step {
  margin-left: 0;
}

.form-steps-tabs-indicator .form-step.last-step {
  margin-right: 0;
}

.form-steps-tabs-indicator .form-step-tab {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  color: #4d4d4d;
  transition: color 0.6s;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.form-steps-tabs-indicator .passed-opened-step .form-step-tab {
  background-color: #bbdff8;
  color: #fff;
}

.form-steps-tabs-indicator .passed-opened-step .form-step-tab.active {
  background-color: var(--active-blue-color);
  color: #fff;
}

@media screen and (max-width: 768px) {
  .form-steps-tabs-indicator {
    height: 50px;
    margin: 40px 0 0;
  }

  .form-steps .form-step:nth-child(2) {
    left: 0;
  }
}

.language-selector .language-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 4px;
}

.language-selector .language-item .caret {
  margin-left: auto;
}

.language-selector .language-item .caret:after {
  width: 8px;
  height: 4px;
  content: ' \25BE';
}

.language-selector .icon-lng-container {
  margin-right: 4px;
  display: flex;
}
