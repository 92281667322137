.product-quotes-container {
  width: 96%;
  max-width: 1090px;
  margin-left: auto;
  margin-right: auto;
}

.product-quote-container {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.product-quotes-header h3 {
  font-size: 24px; 
  color: black;
  font-weight: bold;
  margin: 10px 0 20px;
}

.heading-label {
  color: var(--common-text-color); 
  font-size: 12px; 
  text-transform: uppercase;
}

.heading-link {
  color: var(--active-blue-color);
  cursor: pointer;  
}

.product-description {
  display: inline-flex;
  margin: 30px 0;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  padding: 14px 0;
  white-space: nowrap;
  width: 100%;
  max-width: 750px;
}

.product-description .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0;
}

.product-description .col:last-child {
  padding-right: 0;
  margin-right: 0;
}

.product-description .product-photo {
  max-width: 100px;
}

.product-description .date {
  opacity: .6;
  font-size: 12px;
}

.product-description .btn {
  min-width: 174px;
}

.add-to-favorites {
  padding: 3px 4px 5px;
  margin: 0 -3px;
  cursor: pointer;
  position: relative;
  top: -.2rem;
}

.product-quotes-container .table {
  margin: 15px 0;
}

.dashboard-container .product-quotes-container .table-container .table td {
  font-size: 15px;
}

.dashboard-container .product-quotes-container .table-container tr td:first-child {
  padding-left: 30px;
}

.product-quotes-container .table-container .table tr {
  position: relative;
  height: 115px;
}
.table-container .table-link {
  position: absolute; 
  width: 100%; left: 0; top: 0; height: 100%;
}

