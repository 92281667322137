.company-choose-container .letter-circle-container {
  float: left;
}

.company-choose-container .form-description {
  margin-bottom: 34px;
}

.company-choose-container .company-item {
  cursor: pointer;
  margin-bottom: 16px;
}

.company-choose-container .company-item.selected {
  background-color: #0095ff;
  border: solid 1px #0095ff;
}
