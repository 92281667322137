.dashboard-container {
  position: relative;
}

.dashboard-container .content {
  width: 100%;
  padding: 36px 50px 36px 300px;
}

.dashboard-container .content.hide-left-menu {
  padding: 36px 50px;
}

.dashboard-container .content-blur {
  filter: blur(3px);
}

.dashboard-container .dashboard-top-menu {
  border-bottom: solid 1px #d9d9d9;
  height: 40px;
  padding-top: 8px;
  margin-bottom: 60px;
}

.dashboard-container .page-header {
  margin-bottom: 26px;
}

.dashboard-container .section-header {
  margin-bottom: 32px;
}

.dashboard-container .form-group label {
  color: black;
}

.dashboard-container .form-group label.error.custom {
  color: #fe374f;
}

.dashboard-container {
  --table-border-color: #e9e9e9;
}

.dashboard-container .table .action {
  padding: 3px 0;
}

.dashboard-container .table tr {
  height: 83px;
}

.dashboard-container .table td {
  font-size: 18px;
}

.dashboard-container .table td,
.dashboard-container .table th {
  padding: 0;
  vertical-align: middle;
  border-bottom: 1px solid var(--table-border-color);
}

.dashboard-container .table td:last-child {
  text-align: center;
}

.dashboard-container .table thead th {
  font-size: 14px;
  font-weight: bold;
  color: #3a3a3a;
  border-top: none;
  padding-left: 5px;
  padding-right: 5px;
}

.dashboard-container .table thead th:last-child {
  width: 72px;
}

.dashboard-container .table.actions-first-column td:last-child {
  text-align: left;
}

.dashboard-container .table.black-table thead th {
  color: #080513;
  border-top: none;
}

.dashboard-container .table .popup-content {
  box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.18);
  border-radius: 4px;
}

.dashboard-container .triangle-right {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 7px solid white;
  border-bottom: 7px solid transparent;
}

.dashboard-container .triangle-top {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
}

.dashboard-container form .actions-bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  bottom: -65px;
  padding: 14px;
  border-top: solid 1px #d9d9d9;
  margin-top: 168px;
}

.dashboard-container form .actions-bottom-centered {
  display: flex;
  justify-content: center;
  border: none;
  padding: 0 0 40px;
}

.dashboard-container form .actions-bottom > * {
  margin-left: 16px;
}

.dashboard-container form .actions-bottom button {
  width: 174px;
  height: 36px;
}

.dashboard-container form .actions-bottom .wide-button {
  width: auto;
  padding: 0 30px;
}

.dashboard-container form .section-header {
  padding-top: 25px;
  margin-top: 40px;
}

.dashboard-container form.padding-bottom-180 {
  padding-bottom: 180px;
}

.dashboard-container .form-header {
  margin-bottom: 13px;
}

.dashboard-container .form-header-icon {
  margin-right: 10px;
}
.dashboard-container .form-header-description {
  margin-bottom: 75px;
  line-height: 1.81;
}
.dashboard-container .section-header-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 36px;
  width: 100%;
}

.dashboard-container .section-question {
  font-size: 16px;
  color: black;
  font-weight: 500;
}

.dashboard-container .section-answer {
  padding: 10px 0;
  font-size: 18px;
}

.dashboard-container .sticky-menu {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: solid 1px #d9d9d9;
  height: 41px;
}

.dashboard-container form textarea {
  width: 100%;
  height: 100px;
}

.dashboard-container .icon-close-container {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 50px;
  padding: 3px;
}

.dashboard-container button .icon {
  margin-right: 9px;
  display: inline-block;
  vertical-align: text-bottom;
}

.dashboard-container form .photos-container {
  margin-top: 12px;
}

.dashboard-container form .image-preview-container {
  margin-right: 19px;
}

.dashboard-container form .img-thumbnail {
  padding: 0;
  border-radius: unset;
  background-color: #f4f3f3;
  border: solid 1px #efefef;
}

.dashboard-container .form-control::placeholder {
  opacity: 0.5;
}

.dashboard-container .muted-text {
  opacity: 0.6;
}

.dashboard-container .description-text {
  font-size: 12px;
}

.dashboard-container .important-text {
  font-weight: bold;
  color: #262626;
}

.dashboard-container .danger-text {
  color: var(--danger-red-color);
}

.form-group.no-margin-bottom {
  margin-bottom: 0;
}

.form-group.half-margin-bottom {
  margin-bottom: 12px;
}

.form-group .link {
  color: var(--active-blue-color);
  cursor: pointer;
}

.dashboard-container .table a {
  color: var(--common-text-color);
  cursor: pointer;
  transition: color 0.3s;
}

.dashboard-container .table a:hover {
  color: var(--active-blue-color);
  outline: none;
}

.dashboard-container .popup-content .icon-close-container {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  transition: 0.35s;
  opacity: 0.8;
}

.dashboard-container .popup-content .icon-close-container:hover,
.dashboard-container .popup-content .icon-close-container:focus {
  opacity: 1;
}

.dashboard-container .form-group .form-value {
  font-size: 18px;
}

.dashboard-container .form-value.vertically-centered {
  line-height: 2;
}

.dashboard-container .table-container .table td {
  font-size: 16px;
  padding: 20px 5px;
  line-height: 2;
  max-width: 280px;
}

.dashboard-container .table-container .table tbody tr:hover {
  background-color: #f9f9f9;
}

.dashboard-container .table-container .table thead tr {
  height: 52px;
}

.dashboard-container .table-container .table th:first-child,
.dashboard-container .table-container .table td:first-child {
  padding-left: 12px;
}

.dashboard-container .table-container .table td.top-baseline {
  padding-top: 40px;
  vertical-align: top;
}

.dashboard-container .label-block-blue {
  border-radius: 4px;
  color: white;
  background-color: var(--active-blue-color);
  border: solid 1px #038bec;
  padding: 6px 18px;
  margin-right: 24px;
}

.dashboard-container > .spinner-loading-container {
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -10px 0 0 -10px;
}

@media screen and (max-width: 768px) {
  .dashboard-container form .actions-bottom {
    justify-content: center;
    padding: 18px 0;
    margin-top: 40px;
  }

  .dashboard-container form .actions-bottom > * {
    margin: 0;
  }

  .dashboard-container form .actions-bottom .wide-button {
    width: 100%;
    max-width: 290px;
    font-size: 0.95em;
  }

  .dashboard-container form .section-header {
    margin: 15px 0 20px;
  }

  .dashboard-container .section-header-description {
    font-size: inherit;
    margin-bottom: 25px;
  }

  .dashboard-container .landing-quote-form-container form {
    padding-bottom: 35px;
  }
}
