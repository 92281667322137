.form-steps-indicator {
  position: relative;
  width: 475px;
  height: 12px;
  margin: 10px auto 80px;
  background-color: #d8d8d8;
}

.form-steps-indicator .form-steps-fill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: var(--active-blue-color);
  transition: all 0.6s;
}

.form-steps-indicator .form-steps {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -18px;
  display: flex;
  justify-content: space-between;
}

.form-steps .form-step {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 30px;
}

.form-steps .form-step.first-step {
  margin-left: -2px;
}

.form-steps .form-step.last-step {
  margin-right: -2px;
}

.form-step .form-step-dot {
  display: block;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: #d8d8d8;
  transition: color 0.6s;
}

.passed-opened-step .form-step-dot {
  background-color: var(--active-blue-color);
}

.form-step .form-step-name {
  margin-top: 10px;
  color: #d8d8d8;
  font-size: 20px;
  font-weight: bold;
  transition: color 0.6s;
}

.passed-opened-step .form-step-name {
  color: var(--active-blue-color);
}

@media screen and (max-width: 768px) {
  .form-steps-indicator {
    width: 100%;
    height: 9px;
  }

  .form-step .form-step-name {
    font-size: 16px;
  }

  .form-steps-indicator .form-steps {
    margin-top: -16px;
  }

  .form-steps .form-step:nth-child(2) {
    position: relative;
    left: -5px;
  }

  .form-step .form-step-dot {
    width: 30px;
    height: 30px;
  }
}
