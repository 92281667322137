.products-container .btn-top-right.add-product {
  width: 139px;
}

form .quantities-section .row:not(:last-child) > .form-group {
  margin-bottom: 65px;
}

form .quantities-section .row:not(:last-child) > .form-group .section-header-description {
  margin-bottom: 0;
}

