.language-selector .language-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 4px;
}

.language-selector .language-item .caret {
  margin-left: auto;
}

.language-selector .language-item .caret:after {
  width: 8px;
  height: 4px;
  content: ' ▾';
}

.language-selector .icon-lng-container {
  margin-right: 4px;
  display: flex;
}