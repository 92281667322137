.pagination {
  display: flex;
  padding: 20px 0;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
}

.pagination-btn {
  cursor: pointer;
  padding: 6px;
  display: inline-block;
  margin: 0 16px;
}

.pagination-btn-number {
  display: inline-block;
  color: var(--common-text-color);
  padding: 6px;
  margin: 0 3px;
  margin-top: 4px;
  cursor: pointer;
}

.pagination-btn-number.active {
  pointer-events: none;
  color: var(--active-blue-color);
  text-decoration: underline;
}

.pagination-btn-number.pagination-space-string {
  cursor: default;
}
