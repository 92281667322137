.sign-container {
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at 51% 2%, #080808, #000000);
  display: flex;
  align-items: center;
  color: white;
  padding: 30px;
}

.sign-container .content {
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #0b0b0b, #000000);
  border: solid 1px #202020;
  margin-left: auto;
  margin-right: auto;
}

.sign-container .form-black-container {
  padding: 30px;
  width: 340px;
}

.sign-container .form-black-container .form-header {
  color: #ffffff;
}

.sign-container .logo {
  width: 44px;
  height: 44px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -22px;
  background-image: url('/img/logo.svg');
}

.sign-container .form-header {
  padding-bottom: 25px;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.sign-container button[type='submit'] {
  width: 100%;
  height: 36px;
  margin-top: 9px;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #39b54a, #34aa44 98%);
  background-color: #34aa44;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.sign-container button[type='submit']:hover,
.sign-container button[type='submit']:focus,
.sign-container button[type='submit']:active {
  background-image: none;
  background-color: #088419;
}

.sign-container .form-control {
  height: 36px;
  border-radius: 4px;
  background-color: #0f0f0f;
  border: solid 1px #2f2f2f;
  color: #ffffff;
  font-size: 14px;
}

.sign-container .form-control:focus {
  border: solid 1px #888888;
  box-shadow: none;
}

.sign-container label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.sign-container .success.common,
.sign-container .error.common {
  margin-bottom: 0;
  margin-top: -12px;
  position: absolute;
}

.sign-container .image-preview-container {
  margin-top: 14px;
}

.sign-container .user-type-column button {
  width: 100%;
  background-color: #000;
  color: #fff;
  border-color: #fff;
  font-size: 14px;
}

.sign-up-complete-container.content-blur {
  filter: blur(3px);
}

.popup-signup-just-done * {
  pointer-events: auto;
}

.popup-signup-just-done .sign-container {
  padding: 0;
  color: white;
}

.popup-signup-just-done .sign-container .form-header {
  margin-top: 15px;
  padding: 0 25px;
  color: white;
}

.popup-signup-just-done .sign-container .content {
  text-align: center;
  padding: 45px 30px 8px;
}

.popup-signup-just-done .sign-container .logo {
  margin-top: -65px;
}

.popup-signup-just-done .sign-container button {
  width: 100%;
}

#modal-root .popup-content.popup-signup-just-done {
  padding: 0 !important;
}
