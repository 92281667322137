.spinner-loading-container {
  position: absolute;
  left: 50%;
  transform: scale(0.7);
  top: 25px;
  z-index: 999999;
}

.spinner-loading-container-embed {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.5);
}

.centered-loading-spinner.spinner-loading-container {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -10px;
  margin-left: -10px;
}
