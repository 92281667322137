.black-page-container .logo-container {
  width: 44px;
  height: 44px;
  position: absolute;
  left: 148px;
  top: -22px;
}

.black-page-container {
  color: white;
}

.black-page-container .form-header {
  text-align: center;
}

.black-page-container .page-header,
.black-page-container .form-header,
.black-page-container .section-header {
  color: white;
}

.black-page-container .form-description {
  opacity: 0.69;
  line-height: 1.5;
  text-align: center;
  color: #ffffff;
}

.black-page-container .company-item {
  border-radius: 4px;
  border: solid 1px #2b2b2b;
  padding: 10px 16px;
  font-size: 14px;
}

.black-page-container button[type='submit'] {
  width: 100%;
  height: 36px;
  margin-top: 40px;
  border: none;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #39b54a, #34aa44 98%);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
