.form-steps-tabs-indicator {
  position: relative;
  width: 100%;
  height: 86px;
  margin: 70px auto 50px;
  background-color: #f5f5f5;
}

.form-steps-tabs-indicator .form-steps {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
}

.form-steps-tabs-indicator .form-step {
  position: relative;
  display: flex;
  width: 33.3%;
  align-items: center;
  flex-direction: column;
  align-items: center;
}

.form-steps-tabs-indicator .form-step.first-step {
  margin-left: 0;
}

.form-steps-tabs-indicator .form-step.last-step {
  margin-right: 0;
}

.form-steps-tabs-indicator .form-step-tab {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  color: #4d4d4d;
  transition: color 0.6s;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.form-steps-tabs-indicator .passed-opened-step .form-step-tab {
  background-color: #bbdff8;
  color: #fff;
}

.form-steps-tabs-indicator .passed-opened-step .form-step-tab.active {
  background-color: var(--active-blue-color);
  color: #fff;
}

@media screen and (max-width: 768px) {
  .form-steps-tabs-indicator {
    height: 50px;
    margin: 40px 0 0;
  }

  .form-steps .form-step:nth-child(2) {
    left: 0;
  }
}
