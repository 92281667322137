.messages-container {
  display: flex;
  min-height: 100vh;
  background-color: #fbfbfb;
}

.messages-chat-rooms {
  height: 100vh;
  overflow: auto;
  width: 33.33%;
  border-right: solid 1px #dddddd;
}

.messages-feed {
  height: 100vh;
  overflow: auto;
  width: 66.66%;
}

.messages-feed .messages-heading {
  padding: 18px 34px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
}

.messages-feed .messages-heading .white-button {
  width: 174px;
  margin-left: 30px;
}

.messages-heading {
  position: sticky;
  top: 0;
  z-index: 2;
  min-height: 148px;
  padding: 35px 24px 22px;
  background-color: #fff;
  border-bottom: 1px solid #e3e8ef;
}

.messages-heading h2 {
  color: #080513;
  font-size: 24px;
}

.messages-heading h3 {
  font-size: 18px;
}

.messages-heading .search-form {
  margin-left: 0;
  height: 40px;
  padding: 0;
}

.messages-heading .messages-filters-search-container {
  width: 100%;
  margin-top: 30px;
}

.messages-heading .messages-filters-search-separator-container {
  width: 14px;
  height: 22px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
}

.messages-heading .messages-filters-search-separator {
  border-left: 2px solid #d9d9d9;
  width: 2px;
  height: 20px;
  top: 9px;
  left: 11px;
  position: absolute;
}

.messages-heading .search-bar {
  width: 100%;
}

.messages-heading .search-bar.opened input {
  width: 100%;
}

.messages-heading .date {
  font-size: 12px;
  color: #4d4d4d;
}

.messages-heading .search-bar .btn-search {
  left: 0;
  transition: left 0.4s;
}

.messages-heading .search-bar.opened .btn-search {
  left: 8px;
}

.messages-container {
  margin: -36px -50px;
}

.messages-heading-button {
  display: flex;
  align-self: flex-end;
  padding: 15px 0;
}

.messages-heading-details {
  width: 100%;
  position: relative;
  padding: 0 250px 0 14px;
}

.messages-heading-details .white-button {
  position: absolute;
  right: 0;
  bottom: 10px;
}

.chat-preview {
  position: relative;
  padding: 26px 18px 18px 27px;
  border-bottom: 1px solid #e3e8ef;
  border-left: 5px solid transparent;
  transition: all 0.35s;
  cursor: pointer;
}

.chat-preview.active {
  border-left-color: #0095ff;
  pointer-events: none;
}

.chat-preview .product-name {
  margin-top: 12px;
}

.chat-heading {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-right: 65px;
}

.chat-heading .time-stamp {
  position: absolute;
  top: 3px;
  right: 0;
  font-size: 14px;
  color: #7f8fa4;
}

.chat-heading .company-name {
  font-size: 16px;
  color: #4d4d4d;
}

.chat-heading .contact-name {
  font-size: 14px;
  color: #7f8fa4;
}

.chat-heading .heading-with-names {
  width: 100%;
}
.chat-heading .company-name,
.chat-heading .contact-name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 60px);
  white-space: nowrap;
}

.indicator-new-messages {
  position: absolute;
  top: 50%;
  right: 18px;
  margin-top: -12px;
  width: 24px;
  height: 24px;
  border-radius: 31px;
  background-image: linear-gradient(to bottom, #fcc94a, #f79824);
}

.counter-new-messages {
  width: 34px;
  height: 24px;
  border-radius: 15px;
  background-color: #f79824;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  top: calc(50% - 8px);
  font-size: 16px;
  color: white;
}

.messages-items {
  display: flex;
  min-height: calc(100vh - 260px);
}

.messages-list-items {
  flex-direction: column;
}

.messages-items .message {
  position: relative;
  padding: 25px 75px 25px 35px;
}

.messages-feed .send-message-form {
  position: sticky;
  bottom: 25px;
  box-shadow: 0 10px 15px 20px rgba(255, 255, 255, 0.6);
}

.send-message-form {
  display: flex;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #dfe3e9;
  margin: 25px 34px;
}

.message-attach-files-container {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 70px;
  transition: all 0.35s;
}

.message-attach-files-container:hover {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

.hidden-attach-files-input {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.message-attach-files-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 17px 15px;
  width: 64px;
  height: 56px;
  border-radius: 0;
  border-right: 1px solid #dfe3e9;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  cursor: pointer;
}

.messages-container .send-message-form .message-text-input {
  height: 100%;
  width: 100%;
  padding: 16px 12px;
  resize: none;
  border: none;
  outline: none;
  white-space: pre;
  font-size: 14px;
}

.message-quote-table {
  width: 100%;
  border: solid 1px #f3f3f3;
  font-size: 14px;
  margin-top: 15px;
}

.message-quote-table th {
  padding: 22px 20px 10px 24px;
  color: black;
  font-weight: 600;
}

.message-quote-table td {
  padding: 10px 20px 10px 24px;
}

.message-quote-table .white-button {
  width: 100%;
}

.message-quote-table .product-name {
  font-size: 16px;
  color: #0095ff;
}

.message-quote-table .quoted-date {
  font-size: 12px;
  color: var(--common-text-color);
  opacity: 0.6;
}

.message-quote-table .target-price {
  font-size: 16px;
  color: var(--common-text-color);
}

.message-quote-table .quantity-mark {
  font-size: 12px;
  padding: 24px;
  color: var(--common-text-color);
}

.message-quote-table .target-price-quantity-mark {
  padding-top: 0;
}

.chat-message {
  font-size: 14px;
  line-height: 1.57;
  color: #354052;
  margin: 11px 0 0;
  padding-left: 66px;
  line-height: 1.71;
}

.chat-message.attachment {
  font-size: 20px;
}

.heading-mark {
  margin: 20px 0 30px;
  border-bottom: 1px solid #e3e8ef;
  text-align: center;
}

.heading-mark .mark-text {
  display: inline-block;
  position: relative;
  top: 0.9em;
  padding: 0 15px;
  color: #7f8fa4;
  background-color: #fafafa;
  font-size: 14px;
}

.heading-mark.new-messages {
  border-bottom: 1px solid #f8a32c;
}

.heading-mark.new-messages .mark-text {
  color: #f8a32c;
}
